
import { Vue, Component } from 'vue-property-decorator'
import { wallet } from '@/utils/wallet'
import { lang } from '@/lang'

@Component({})
export default class HeaderInfo extends Vue {
  private get accoundId (): string {
    return wallet.accountId
  }

  private get showId (): boolean {
    return this.accoundId !== ''
  }

  private get scanUrl (): string {
    return 'https://moonbeam.moonscan.io/address/' + this.accoundId
  }

  private get text () {
    return {
      title: lang('MY_NAME_TITLE'),
      tip: lang('MY_NAME_NOT_CONNECT'),
      gotoScan: lang('MY_NAME_GOTO_ETHERSCAN')
    }
  }
}
