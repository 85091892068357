
import { Vue, Component } from 'vue-property-decorator'
import Identicon from './Identicon.vue'
import Info from './Info.vue'

@Component({
  components: {
    Identicon,
    Info
  }
})

export default class Header extends Vue {

}
