
import { Vue, Component } from 'vue-property-decorator'
import { wallet } from '@/utils/wallet'
import Identicon from 'identicon.js'

@Component({})
export default class Indenticon extends Vue {
  private get accoundId (): string {
    return wallet.accountId
  }

  private get indenticonUrl (): string {
    if (this.accoundId === '') return ''
    const options = {
      foreground: [216, 140, 173, 255],
      background: [255, 255, 255, 255],
      margin: 0.2,
      size: 77,
      format: 'svg'
    }
    return 'data:image/svg+xml;base64,' + new Identicon(this.accoundId.slice(1), (options as any)).toString()
  }
}
